
import './App.css';
import React, { useState, useEffect  } from 'react';
import Schedule from './components/schedule.js';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
function App() {
  const [data, setData] = useState(null);
  return (
      <Schedule />
  );
}
export default App;
