import axios from 'axios';

let apiUrl = 'https://api-v2.i3broadband.com/i3-oss-api/';
let bearerToken = 'jT5TSrkeNhpoLftmenveEBF1UxIwwUtW';


const scheduleingApi = axios.create({
  withCredentials: true,
  baseURL: 'https://api-v2.i3broadband.com/coverage-api/',
  
  
  headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer jT5TSrkeNhpoLftmenveEBF1UxIwwUtW' //temorary added, remove for production
  }
});

export {
    apiUrl,
    axios,
    bearerToken,
};
