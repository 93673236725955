'use client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faExclamation } from '@fortawesome/free-solid-svg-icons';

import { React, useMemo, useRef, useEffect, useState  } from 'react';
import { format, addDays, startOfWeek, endOfWeek, eachDayOfInterval } from 'date-fns'; 
import { axios, apiUrl, bearerToken } from '../Api.js';
import CommunicateClient from './communicateclient.js';

const BearerToken = 'jT5TSrkeNhpoLftmenveEBF1UxIwwUtW';
const startDate = format(addDays(new Date(), 2), 'yyyy-MM-dd');
const endDate = format(addDays(new Date(), 29), 'yyyy-MM-dd');
const ui = 'CustomerScheduling';
const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const currentDate = format(addDays(new Date(), 1), 'yyyy-MM-dd');
let verifyTokenURL = `${apiUrl}token/verify-token`;
let verifySlotURL = `${apiUrl}scheduling/verifySlot`;
let createAppointmentURL = `${apiUrl}scheduling/create`;
let getAvailableSlots = `${apiUrl}scheduling/getSlots`;

// Helper function for headers
function getHeaders() {
    return {
      Authorization: `Bearer ${BearerToken}`,
      'Content-Type': 'application/json',
    }
}

export default function Schedule() {
    const urlParams = new URLSearchParams(window.location.search);
    let [error, setError] = useState(null);
    let [loading, setLoading] = useState(false);
    let [marketID, setMarketID] = useState(null);
    let [weeklyCalendar, setWeeklyCalendar] = useState([]);
    let [calendar, setCalendar] = useState([]);
    let [showConfirm, setShowConfirm] = useState(false);
    let [confirmSlot, setconfirmSlot] = useState([]);
    let [currentDisplayWeekIndex, setCurrentDisplayWeekIndex] = useState(0);
    let [customerToken, setCustomerToken] = useState(urlParams.get('token'));
    let [customerTokenStatus, setCustomerTokenStatus] = useState(false);
    let [showSlotNotAvailable, setShowSlotNotAvailable] = useState(false);
    let [currentMonth, setCurrentMonth] = useState('');
    let [customerID, setCustomerID] = useState('');
    let [ticketID, setTicketID] = useState('');
    let [confirmationData, setConfirmationData] = useState({});
    const [communicationCode, setCommunicationCode] = useState(null);
    const [communicationData, setCommunicationData] = useState(null);
    const [tokenVerified, setTokenVerified] = useState(false);
    

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        setCustomerToken(urlParams.get('token'));
    }, []);

    useEffect(() => {
        async function verifyTokenasync() {
            setLoading(true);
            setError(null);
        
            const verifyTokenParams = {
                ui: ui,
                token: customerToken
            };
        
            try {
                const tokenResponse = await axios.get(verifyTokenURL, {
                    params: verifyTokenParams,
                    headers: getHeaders(),
                });
        
                if (tokenResponse.data.Response.Status.Code === 2000) {
                    setMarketID(tokenResponse.data.Response.Data.MarketID);
                    setCustomerID(tokenResponse.data.Response.Data.CustomerID);
                    setTicketID(tokenResponse.data.Response.Data.TicketID);
        
                    const expiryTimestamp = new Date(tokenResponse.data.Response.Data.ExpiryTimestamp);
                    const now = new Date();
        
                    if (tokenResponse.data.Response.Data.WindowClosedBit === 1) {
                        console.log('WindowClosedBit = 1');
                        setCommunicationCode(4003);
                        return false;
                    } else if (tokenResponse.data.Response.Data.WindowClosedBit !== 1 && now >= expiryTimestamp) {
                        console.log('Timestamp Expired');
                        setCommunicationCode(4000); 
                        return false;
                    } else if (tokenResponse.data.Response.Data.AlreadyInstalled) {
                        console.log('Already installed');
                        setCommunicationCode(4001);
                        return false;
                    } else if (tokenResponse.data.Response.Data.AccountCancelled) {
                        console.log('Order Cancelled');
                        setCommunicationCode(4002, '');
                        return false;
                    } else {
                        //Valid Toekn
                        setCustomerTokenStatus(true); 
                        return true;
                    }
                } else {
                    console.log('Token verification != 2000');
                    setCommunicationCode(4000);
                    return false;
                }
            } catch (err) {
                console.log('Error Cought');
                setCommunicationCode(4000);
            } finally {
                setLoading(false);
            }
        }
        verifyTokenasync();
    }, [customerToken]);
    
    useEffect(() => {
        async function fetchSlots(){
            if(customerTokenStatus){
                await getSlots();
            }
        }
        fetchSlots();
    }, [customerTokenStatus, marketID]);
    
    useEffect(() => {
        async function setupCalendar() {
            if (weeklyCalendar && weeklyCalendar.length > 0) {
                await generateCalendar();
                
            }
        }
        setupCalendar();
    }, [weeklyCalendar]);

    useEffect(() => {
        async function setupMonth() {
            calederNavigation(0);
        }
        setupMonth();
    }
    , [calendar]);

    const generateCalendar = () => {
        const _startDate = startOfWeek(currentDate, { weekStartsOn: 0 });
        const end = endOfWeek(addDays(currentDate, 29), { weekStartsOn: 0 });
        const days = eachDayOfInterval({ start: _startDate, end: end });
        const weeks = [];
        let week = [];
    
        days.forEach((day, index) => {
            if (index % 7 === 0 && index !== 0) {
                weeks.push([...week]);
                week = [];
            }
    
            const dayObj = getSlotsForDay(day);
    
            if (dayObj.filteredTimeSlotsConcise) {
                week.push({ date: day, slots: dayObj.filteredTimeSlotsConcise });
                if (index === days.length - 1) {
                    weeks.push([...week]);
                }
            }
        });
        setCalendar(weeks);
    };
    
    const getSlotsForDay = (date) => {
        const matchingSlots = weeklyCalendar.filter(day => format(new Date(day.date), 'MM/dd/yyyy') === format(date, 'MM/dd/yyyy'));
        const filteredTimeSlotsConcise = matchingSlots[0]?.timeSlots ?? [];
    
        return {
            filteredTimeSlotsConcise
        };
    };
    
    const getSlots = async () => {
        setLoading(true);
        setError(null);
    
        const params = {
            ui: ui,
            market_id: marketID,
            start_date: startDate.toString(),
            end_date: endDate.toString(),
        };
    
        try {
            const response = await axios.get(getAvailableSlots, {
                params: params,
                headers: {
                    Authorization: `Bearer ${BearerToken}`,
                    'Content-Type': 'application/json',
                },
            });
            const jsonData = response.data.Response.Data;
            const dataArray = Object.entries(jsonData).map(([date, data]) => ({
                date, data
            }));
    
            const newWeeklyCalendar = [];
    
            dataArray.forEach(function (dayData) {
                if (dayData !== null && dayData !== undefined) {
                    const day = {};
                    const date = dayData.date;
                    const prettyDate = dayData.data['PrettyDate'];
                    const dayName = prettyDate.split(",")[0];
    
                    day.date = date;
                    day.prettyDate = prettyDate;
                    day.dayName = dayName;
    
                    if (dayData.data.slotNames && dayData.data.slotWindows) {
                        day.timeSlots = [];
    
                        const desiredOrder = ["morning", "midday", "afternoon", "evening"];
    
                        dayData.data.slotNames.forEach((slotName, index) => {
                            const slotWindow = dayData.data.slotWindows[index];
                            const lowerCaseSlotName = slotName.toLowerCase();
                            const slotID = dayData.data.slotIDs[index];
                            const position = desiredOrder.indexOf(lowerCaseSlotName);
                            const prettyDate = dayData.data['PrettyDate'];
                            const date = format(new Date(dayData.date), 'yyyy-MM-dd');
    
                            if (position !== -1) {
                                day.timeSlots[position] = { name: lowerCaseSlotName, window: slotWindow, slot_id: slotID, prettyDate: prettyDate, date: date };
                            }
                        });
    
                        day.timeSlots = day.timeSlots.filter(slot => slot !== undefined);
                    }
                    newWeeklyCalendar.push(day);
                }
            });
            setWeeklyCalendar(newWeeklyCalendar);
    
        } catch (err) {
            console.error('API Error:', err);
            setError(err.message || 'An error occurred.');
            if (err.response) {
                console.error("Response data:", err.response.data);
                console.error("Response status:", err.response.status);
                console.error("Response headers:", err.response.headers);
                setError(`API Error: ${err.response.status} - ${err.response.data?.message || "Unknown error"}`);
            } else if (err.request) {
                console.error("Request error:", err.request);
                setError("API Error: No response received.");
            } else {
                console.error('Error message:', err.message);
                setError(`API Error: ${err.message}`);
            }
        } finally {
            setLoading(false);
        }
    }
   
    function calederNavigation(direction) {
        let newIndex = currentDisplayWeekIndex;
        if (direction === 1) {
            newIndex++;
        } else if (direction === -1) {
            newIndex--;
        } else {
            newIndex = 0;
        }

        if (newIndex < 0 || newIndex > 3) return;

        setCurrentDisplayWeekIndex(newIndex);

        if (calendar[newIndex] && calendar[newIndex].length > 0) {
            const __firstDate = calendar[newIndex][0].date;
            const __lastDate = calendar[newIndex][6].date;
            const firstMonth = __firstDate.toLocaleString('default', { month: 'long' });
            const firstYear = __firstDate.getFullYear();
            const lastMonth = __lastDate.toLocaleString('default', { month: 'long' });
            const lastYear = __lastDate.getFullYear();

            if (firstMonth === lastMonth && firstYear === lastYear) {
                setCurrentMonth(`${firstMonth} ${firstYear}`);
            } else {
                setCurrentMonth(`${firstMonth} ${firstYear} - ${lastMonth} ${lastYear}`);
            }
        }
    }

    const closeConfirmation = () => {
        setShowConfirm(false);
    }

    async function closeSlotNotAvailable () {
        setShowSlotNotAvailable(false);
        await getSlots();
        await generateCalendar();
    }

    function openConfirmation(slot){
        //{"name":"morning","window":"8am - 12pm","slot_id":1,"prettyDate":"Thursday, March 13th, 2025","date":"2025-03-13"}
        const _slot_date = new Date(slot.date);
        if (!isNaN(_slot_date)) {
            confirmationData.day = _slot_date.toLocaleDateString('en-US', { weekday: 'long' });
            confirmationData.date = _slot_date.toLocaleDateString('en-US', {
              month: '2-digit',
              day: '2-digit',
              year: 'numeric',
            });
            confirmationData.prettyDate = slot.prettyDate;
            confirmationData.time = slot.window;
            confirmationData.dayString = slot.name;
            confirmationData.slotID = slot.slot_id;
            confirmationData.apiDate = slot.date
          }

        setShowConfirm(true);
        confirmSlot['name'] = slot.name;
        confirmSlot['prettyDate'] = slot.prettyDate;
        confirmSlot['date'] = slot.date;
        
    }

    async function submitConfirmation(slot) {
        //_date, _slotName, _slotID
        //{"day":"Friday","date":"03/14/2025","prettyDate":"Saturday, March 15th, 2025","time":"1pm - 4pm","dayString":"afternoon"} slotID
       //{"day":"Friday","date":"03/14/2025","prettyDate":"Saturday, March 15th, 2025","time":"1pm - 4pm","dayString":"afternoon","slotID":3,"apiDate":"2025-03-15"}

       //setCommunicationCode(2000, confirmationData);
         try {
            setLoading(true);
            const response = await axios.get(verifySlotURL, {
                params: {
                    ui: 'CustomerScheduling',
                    market_id: marketID,
                    date: slot.apiDate,
                    slot_name: slot.dayString
                },
                headers: {
                    Authorization: `Bearer ${BearerToken}`,
                    'Content-Type': 'application/json',
                },
            });
            const validated = response.data.Response.Data;
            if(validated) {
                   //Create Appointment
                    const appointmentBody = {
                        "ui": 'CustomerScheduling',
                        "market_id": marketID,
                        "customer_id": customerID,
                        "ticket_id": ticketID, 
                        "timeblock_id": slot.slotID,
                        "date": slot.apiDate,
                        "token": customerToken,
                    };

                    //{"day":"Friday","date":"03/14/2025","prettyDate":"Saturday, March 15th, 2025","time":"1pm - 4pm","dayString":"afternoon","slotID":3,"apiDate":"2025-03-15"}
                    console.log('Submission Body : ' + JSON.stringify(appointmentBody));
                    //{"ui":"CustomerScheduling","market_id":16,"customer_id":16,"ticket_id":976660,"timeblock_id":3,"date":"2025-03-19","token":"1d60cda1b469d9f3c1f21b68c3e26c8f"}



                    
                   try {
                        const appointmentResponse = await axios.post(createAppointmentURL, appointmentBody, { 
                        headers: getHeaders(BearerToken),
                        });

                        console.log("Appointment created response:", appointmentResponse); 
                        if(appointmentResponse.data.Response.Status.Code == 2000){
                        //Success
                        setCommunicationCode(2000, appointmentBody);
                        }
                    } catch (err) {
                        setCommunicationCode(4004, appointmentBody);
                    }
                
            }else {
                setShowSlotNotAvailable(true);
            }
        } catch (err) {
            console.log(err);
            setCommunicationCode(4004);
        } finally {
            setLoading(false);
        }
    }

    return (
        <div>
            {communicationCode && (
                <CommunicateClient
                    code={communicationCode}
                    data={confirmationData}
                />
            )}

            {(!communicationCode && customerTokenStatus) &&
                <>

                <div className="calendar-container container">
                    <h2>Get Your Fiber Installed - Schedule Now!</h2>
                    {(!calendar[currentDisplayWeekIndex] || loading) && <img className="loading icon" src="https://www.i3broadband.com/wp-content/themes/i3/assets/images/schedule_loading.gif" />}
                

                    


                    {(!loading && calendar[currentDisplayWeekIndex]) && <>
                    <p className="regions-pre">Select your preferred date and time window, our technician will arrive within that timeframe. Installation will take up to 2 hours to complete.</p>
                    <div className="regions">
                        <span className="morning">Morning: 8am - Noon</span>|
                        <span className="midday">Midday: 10am - 2pm</span>|
                        <span className="afternoon">Afternoon: Noon - 4pm</span>
                    </div>

                    <div className="table-header-month" > 
                      <span>{ currentMonth }</span> 
                      <div className="table-header-btn-wrap">
                        <button disabled={currentDisplayWeekIndex <= 0} onClick={ () => calederNavigation(-1)}><FontAwesomeIcon icon={faAngleLeft} /></button>
                        <button disabled={currentDisplayWeekIndex == 0} onClick={ () => calederNavigation(0)}>Current Week</button>
                        <button disabled={currentDisplayWeekIndex > 3} onClick={ () => calederNavigation(1)}><FontAwesomeIcon icon={faAngleRight} /></button>
                      </div>
                    </div> </>}

                
                
                    <div className="calendar-table-wrap">
                    {!loading && (
                        <>

                            {calendar[currentDisplayWeekIndex] && ( 
                                <>
                                <table className="calendar-table">
                                    <thead>
                                        <tr>
                                            {calendar[currentDisplayWeekIndex].map((day, idx) => (
                                                <th key={day.date}>
                                                    <span className="header-day">{dayNames[idx]}</span>
                                                    <span className="header-date">{day.date ? day.date.getDate() : ''}</span>
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            {calendar[currentDisplayWeekIndex].map((day) => (
                                                <td key={day.date}>
                                                    {day.slots && (
                                                        <div>
                                                            {day.slots.map((matchingSlot, msIndex) => (
                                                                matchingSlot && (
                                                                    <div key={msIndex}>
                                                                        {matchingSlot.name === 'morning' && (
                                                                            <button
                                                                                className="sl-morning"
                                                                                onClick={() => openConfirmation(matchingSlot)}
                                                                            >
                                                                                {matchingSlot.name}
                                                                            </button>
                                                                        )}
                                                                        {matchingSlot.name === 'midday' && (
                                                                            <button
                                                                                className="sl-midday"
                                                                                onClick={() => openConfirmation(matchingSlot)}
                                                                            >
                                                                                {matchingSlot.name}
                                                                            </button>
                                                                        )}
                                                                        {matchingSlot.name === 'afternoon' && (
                                                                            <button
                                                                                className="sl-afternoon"
                                                                                onClick={() => openConfirmation(matchingSlot)}
                                                                            >
                                                                                {matchingSlot.name}
                                                                            </button>
                                                                        )}
                                                                        {matchingSlot.name === 'evening' && (
                                                                            <button
                                                                                className="sl-evening"
                                                                                onClick={() => openConfirmation(matchingSlot)}
                                                                            >
                                                                                {matchingSlot.name}
                                                                            </button>
                                                                        )}
                                                                    </div>
                                                                )
                                                            ))}
                                                        </div>
                                                    )}
                                                </td>
                                            ))}
                                        </tr>
                                    </tbody>


                                </table>

                                <ul className="additional-notes">
                                    <li>An adult (18+) with knowledge of the home must be present to approve installation details.</li>
                                    <li>We love Pets! However, please secure them away from both inside and outside areas where our installers may require access. </li>
                                </ul>
                                </>

                            )}

                            
                        </>
                    )}

                       

                    </div>

                </div> 

                </>
            }
            





            {showConfirm && <div className="modal-wrap" onClick={closeConfirmation}>
                <div className="conf-modal">
                    <div className="conf-modal-title">
                    Appointment Confirmation
                    <button onClick={closeConfirmation}>X</button>
                    </div>
                    <div className="conf-modal-body">
                    Please confirm your appointment for the <span>{confirmationData.dayString}</span> of {confirmationData.prettyDate}.
                    </div>
                    <div className="conf-modal-footer">
                    <button className="btn btn-cancel" onClick={closeConfirmation}>
                        Cancel
                    </button>
                    <button
                        className="btn btn-submit"
                        onClick={() => submitConfirmation(confirmationData)}
                    >
                        Confirm
                    </button>
                    </div>
                </div>
            </div>}



            {showSlotNotAvailable && <div v-if="showCommunicationDialog" className="modal-wrap dialog-modal" onClick={closeSlotNotAvailable}>
                <div className="conf-modal">
                <div className="conf-modal-title">
                    <FontAwesomeIcon className="message-icon warning" icon={faExclamation} />
                </div>
                <div className="conf-modal-body">
                    Uh-oh! The date and/or time you selected is unavailable. Please choose a different option to schedule your appointment.
                </div>
                <div className="conf-modal-footer">
                    <button className="btn btn-submit" onClick={closeSlotNotAvailable}>Okay</button>
                </div>
                </div>
            </div>}




        </div>



    );
}

//export default Schedule;