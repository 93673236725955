'use client';
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faExclamation, faCalendarCheck } from '@fortawesome/free-solid-svg-icons';

function CommunicateClient({ code, data}) {
  const [communicationType, setCommunicationType] = useState(-1);
  const [communicationTitle, setCommunicationTitle] = useState('');
  const [communicationMessage, setCommunicationMessage] = useState('');
  const [showCommunication, setShowCommunication] = useState(false);
  const [showCommunicationDialog, setShowCommunicationDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setCommunicationType(-1);
    setCommunicationTitle('');
    setCommunicationMessage('');
    setShowCommunication(false);
    setShowCommunicationDialog(false);
    setLoading(true);

    console.log('Communication Params : ' + JSON.stringify(data));

    switch (code) {
      // ... your switch cases for communication messages ...
      case 2000:
        setCommunicationType(1);
        setCommunicationTitle('Appointment Confirmed');
        setCommunicationMessage(
          `<p>Excellent, your appointment has been successfully scheduled for <span class="capitalize">${data.day}</span> <span class="capitalize">${data.dayString}</span>, <span>${data.date}</span>. A technician will arrive between ${data.time}, and you will receive a text reminder beforehand. Your professional  installation may take up to 2 hours to complete.</p>
            <br/>
            <div style="text-align: left; font-size: 20px;">
              <strong>Important Reminders:</strong>
              <ul>
                <li>An adult (18+) with knowledge of the home must be present to approve installation details.</li>
                <li>We love pets! For Safety, please secure them away from areas our installers may need to access, both inside and outside.</li>
              </ul>
            </div>
          <p style="margin-top: 5%;font-size: 2rem;color:#8e00ae;">Thank you for Choosing i3 Broadband.  We're happy you are here!</p>`
        );
        setShowCommunication(true);
        break;
      case 4000:
        setCommunicationType(0);
        setCommunicationTitle('');
        setCommunicationMessage(
          'We’re sorry, but it looks like changes cannot be made to your order at this time. This could be due to a pending order on record. Please call us at (877) 976-0711 or chat with an agent for assistance with scheduling.'
        );
        setShowCommunication(true);
        break;
      case 4001:
        setCommunicationType(0);
        setCommunicationTitle('');
        setCommunicationMessage(
          'It seems like service is already installed at this address. If this is an error, please chat with us or call us at (877) 976-0711 so we can assist you.'
        );
        setShowCommunication(true);
        break;
      case 4002:
        setCommunicationType(0);
        setCommunicationTitle('');
        setCommunicationMessage(
          'It looks like your order has been cancelled. Try placing a new order now or reach out to us via chat or phone at (877) 976-0711 for help.'
        );
        setShowCommunication(true);
        break;
        case 4003:
          setCommunicationType(2);
          setCommunicationTitle('');
          setCommunicationMessage(
            'Your appointment has been confirmed. Connect with us via email or phone if you have any questions or need to make changes.'
          );
          setShowCommunication(true);
          break;
        case 4004:
          setCommunicationType(0);
          setCommunicationTitle('');
          setCommunicationMessage(
            'Oops! It looks like something went wrong. Please try again or contact us for help at (877) 976-0711 or via chat.'
          );
          setShowCommunication(true);
          break;
      default:
        break;
    }
    setLoading(false);
  }, [code,data]);

  return (
    <div className="container">
      <div className="message-container container">
      {communicationTitle && <div className="message-title">{communicationTitle}</div>}
      {communicationType === 1 && (
            <FontAwesomeIcon className="message-icon success" icon={faThumbsUp} /> 
          )}
        {communicationType === 0 && (
            <FontAwesomeIcon className="message-icon warning" icon={faExclamation} />
          )}
        {communicationType === 2 && (
            <FontAwesomeIcon className="message-icon regular" icon={faCalendarCheck} />
          )}
        <p dangerouslySetInnerHTML={{ __html: communicationMessage }} />
      </div>
    </div>
  );
}

export default CommunicateClient;